.tippy-tooltip.tooltip-theme {
  background-color: #00b000;
  color: white;
}

.tippy-tooltip.tooltip-theme[data-placement^="top"] .tippy-arrow {
  border-top-color: #00b000;
}
.tippy-tooltip.tooltip-theme[data-placement^="bottom"] .tippy-arrow {
  border-bottom-color: #00b000;
}
.tippy-tooltip.tooltip-theme[data-placement^="left"] .tippy-arrow {
  border-left-color: #00b000;
}
.tippy-tooltip.tooltip-theme[data-placement^="right"] .tippy-arrow {
  border-right-color: #00b000;
}

.st0 {
  fill: #efefef;
  stroke: #6e6e6e;
  stroke-width: 0.4;
}
.st1 {
  font-family: "Open Sans", sans-serif;
}
.st2 {
  font-size: 12px;
}
